import { BoxWindow } from "../components/BoxWindow"
import { Outlet } from "react-router-dom"
import { createContext, useState } from "react";

export const FormContext = createContext();

export const DEFAULT_MEME_CREATE_FORM_VALUES = {
    memeType: undefined,
    video: undefined,
    removeBg: false,
    chromaKey: '#03fc03',
    similarity: 0.3,
    smoothness: 0.15,
    spill: 0,
    title: '',
    tags: []
};

export const MemeCreatePage = () => {

    const [formData, setFormData] = useState(DEFAULT_MEME_CREATE_FORM_VALUES);

    return (
        <BoxWindow margin={{ top: 'medium' }}>
            <FormContext.Provider value={{ formData, setFormData }}>
                <Outlet />
            </FormContext.Provider>
        </BoxWindow>
    )
}