import { Box, Image, Text } from "grommet"
import { useTranslation } from "react-i18next";
import { MemeCreateWizardStep } from "./MemeCreateWizardStep";
import { useContext, useEffect } from "react";
import { DEFAULT_MEME_CREATE_FORM_VALUES, FormContext } from "../../../pages/MemeCreatePage";
import { useNavigate } from "react-router-dom";

const memeTypes = {
    FULLSCREEN: 'FULLSCREEN',
    SPOT: 'SPOT',
    MIXED: 'MIXED'
}

export const MemeCreateWizardTypeStep = () => {

    const { t } = useTranslation('memes');
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);

    const onOptionClick = (memeType) => {
        setFormData(prevValue => ({ ...prevValue, ...{ memeType: memeType } }));
        navigate('/memes/create/upload');
    }

    useEffect(() => {
        setFormData(DEFAULT_MEME_CREATE_FORM_VALUES);
    }, [])

    return (
        <MemeCreateWizardStep step={1} stepTitle={t('selectMemeTypeTitle')}>
            <Box direction="row-responsive" gap="medium" justify="center">
                <CreateOption onClick={() => onOptionClick(memeTypes.FULLSCREEN)} title={'Fullscreen'} description={t('fullscreenMemeCreateDescription')} img={`${process.env.PUBLIC_URL}/images/fullscreen_meme.png`} />
                <CreateOption onClick={() => onOptionClick(memeTypes.SPOT)} title={'Spot'} description={t('spotMemeCreateDescription')} img={`${process.env.PUBLIC_URL}/images/spot_meme.png`} />
                <CreateOption onClick={() => onOptionClick(memeTypes.MIXED)} title={'Mixed'} description={t('mixedMemeCreateDescription')} img={`${process.env.PUBLIC_URL}/images/mixed_meme.png`} />
            </Box>
        </MemeCreateWizardStep>
    )
}

const CreateOption = ({ title, description, img, onClick }) => {
    return (
        <Box overflow={'hidden'} basis="1/3" direction="column" border={{ color: '#ffffff5c', size: '1px' }} round hoverIndicator={{ background: { color: 'background-contrast' }, elevation: 'medium' }} onClick={onClick}>
            <Box>
                <Image src={img} />
            </Box>
            <Box direction="column" gap="small" margin={'medium'}>
                <Text weight={'bold'} size="medium">{title}</Text>
                <Text>{description}</Text>
            </Box>
        </Box>
    )
}