import { forwardRef, useImperativeHandle, useLayoutEffect, useRef, useState } from "react"
import Video from "./Video"
import WebGLCanvas from "./WebGLCanvas"
import { Box } from "grommet";

const transparentBackgroundArea = 'linear-gradient(45deg, rgba(0, 0, 0, 0.15) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.15) 75%, rgba(0, 0, 0, 0.15) 0px) 0px 0px / 20px 20px repeat, linear-gradient(45deg, rgba(0, 0, 0, 0.15) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.15) 75%, rgba(0, 0, 0, 0.15) 0px) 10px 10px / 20px 20px repeat, white';

const WebGLVideo = forwardRef(({ src, removeBgConfig, autoPlay, loop, muted, onTimeUpdate, onCanPlay, onPlay, onPause, maxHeight, showTransparentBg, ...props }, ref) => {

    const canvasRef = useRef();
    const videoRef = useRef();

    const [canvasSize, setCanvasSize] = useState({ height: 0, width: 0 });

    const onVideoPlaying = () => {
        canvasRef.current.play(videoRef.current);
    }

    const onVideoCanPlay = (event) => {
        const video = event.target;

        const aspectRatio = video.videoWidth / video.videoHeight;
        // const container = video.parentElement;
        const containerWidth = video.clientWidth;
        const containerHeight = video.clientHeight;

        let displayWidth, displayHeight;

        if (containerWidth / containerHeight > aspectRatio) {
        // Container is wider than video aspect ratio → Height is maxed out
            displayHeight = containerHeight;
            displayWidth = containerHeight * aspectRatio;
        } else {
        // Container is taller than video aspect ratio → Width is maxed out
            displayWidth = containerWidth;
            displayHeight = containerWidth / aspectRatio;
        }

        // setCanvasSize({ height: videoRect.height, width: videoRect.width });

        setCanvasSize({ height: displayHeight, width: displayWidth });

        onCanPlay(event);
    }

    // useOnWindowResize(() => {
    //     const videoRect = videoRef.current.getBoundingClientRect();
    //     console.log(videoRect);
        
    //     setCanvasSize({ height: videoRect.height, width: videoRect.width });
    // }, true, true)

    useLayoutEffect(() => {
        const observer = new ResizeObserver(() => {
            const aspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
            // const container = video.parentElement;
            const containerWidth = videoRef.current.clientWidth;
            const containerHeight = videoRef.current.clientHeight;

            let displayWidth, displayHeight;

            if (containerWidth / containerHeight > aspectRatio) {
            // Container is wider than video aspect ratio → Height is maxed out
                displayHeight = containerHeight;
                displayWidth = containerHeight * aspectRatio;
            } else {
            // Container is taller than video aspect ratio → Width is maxed out
                displayWidth = containerWidth;
                displayHeight = containerWidth / aspectRatio;
            }

            // console.log(`Calculated size: ${displayWidth}x${displayHeight}`);


            // setCanvasSize({ height: videoRect.height, width: videoRect.width });

            setCanvasSize({ height: displayHeight, width: displayWidth });
        });

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => observer.disconnect();
    }, []);

    const play = () => {
        videoRef.current?.play();
    };

    const stop = () => {
        videoRef.current?.pause();
    };

    useImperativeHandle(ref, () => {
        return {
            play,
            stop,
            getVideo: () => videoRef.current
        }
    }, [])

    return (
        <Box style={{ position: 'relative' }} justify="center" {...props}>
            <WebGLCanvas 
                ref={canvasRef} 
                removeBgConfig={removeBgConfig} 
                height={canvasSize.height} 
                width={canvasSize.width}
                style={{ position: 'absolute', opacity: 1, top: '50%', left: '50%', transform: 'translate(-50%, -50%)',  height: `${canvasSize.height}px`, width: `${canvasSize.width}px`, background: showTransparentBg ? transparentBackgroundArea : 'none' }}
            />
            <Video 
                src={src} 
                onCanPlay={onVideoCanPlay}
                onPlay={onPlay}
                onPlaying={onVideoPlaying}
                onPause={onPause}
                onTimeUpdate={onTimeUpdate}
                style={{ opacity: 0, maxHeight: maxHeight ? maxHeight : 'none' }}
                autoPlay={autoPlay}
                loop={loop}
                muted={muted}
                ref={videoRef}
            />
        </Box>
    )
})

export default WebGLVideo;