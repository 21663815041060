import { Box, Button, Card, CardBody, Text } from "grommet"
import { StatusGood } from "grommet-icons"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const MemeCreatedSuccessfully = () => {

    const { t } = useTranslation('memes');
    const navigate = useNavigate();

    return (
        <Card background='#222222'>
            <CardBody>
                <Box justify="center" align="center" height={'100vh'} gap="small">
                    <Text>{t('memeCreatedSuccess')}</Text>
                    <StatusGood size="xxlarge" />
                    <Button label={t('goToMemesBtn')} onClick={() => navigate('/memes')} primary />
                </Box>
            </CardBody>
        </Card>
    )
}