import { Box, Button, FormField, Heading, Image, Keyboard, Markdown, Paragraph, Tag, TextInput } from "grommet"
import { Add } from "grommet-icons";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormFieldWithControls } from "../../../forms/FormFieldWithControls";
import VideoPlayer from "../../webgl/VideoPlayer";
import { MemeCreateWizardStep } from "./MemeCreateWizardStep";
import { FormContext } from "../../../pages/MemeCreatePage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCreateInteractionMutation } from "../../../api/interactionsApi";
import { addNotification } from "../../../slices/notificationsSlice";

const titleValidationRules = [
    {
        regexp: /^.{3,255}$/,
        message: 'Title should be minimum 3 length and maximum 255 characters',
        status: 'error',
    }
]

export const MemeCreateWizardInfoStep = () => {

    const dispatch = useDispatch();
    const [createInteraction, { isLoading: isCreating }] = useCreateInteractionMutation();
    const { t } = useTranslation('memes');
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);

    const cachedVideoPreview = useMemo(() => {
        if (formData.video?.length > 0) {
            const videoFile = formData.video[0];
            return URL.createObjectURL(videoFile);
        }
    }, [formData.video]);
    const [chromaKeyConfig, setChromaKeyConfig] = useState();
    const [memeTag, setMemeTag] = useState('');
    const [memeTags, setMemeTags] = useState(formData.tags);

    const handleTagAdd = (event) => {
        event.preventDefault();
        const hasNotTag = memeTags.indexOf(memeTag.trim()) === -1;
        if (hasNotTag && memeTags.length < 5 && memeTag.length >= 3) {
            setMemeTags([...memeTags, memeTag.trim()]);
        }
        setMemeTag('');
    }

    const handleTagInput = (event) => {
        setMemeTag(event.target.value);
    }

    useEffect(() => {
        setFormData(prevValue => ({ ...prevValue, ...{ tags: memeTags } }));
    }, [memeTags])

    useEffect(() => {
        if (!formData.memeType) {
            navigate('/memes/create');
        }
        if (!formData.video) {
            navigate('/memes/create/upload')
        }
        if (formData.removeBg) {
            setChromaKeyConfig({ 
                chromaKey: formData.chromaKey,
                similarity: formData.similarity,
                smoothness: formData.smoothness,
                spill: formData.spill
            })
        } else {
            setChromaKeyConfig(null);
        }
    }, [formData])

    const handleCreateMeme = () => {
            
        const requestBody = {
            interaction: {
                title: formData.title,
                tags: formData.tags,
                type: formData.memeType,
                ...chromaKeyConfig
            },
            interactionVideo: formData.video[0]
        }

        console.log(requestBody);
        
        // navigate('/memes/create/success');

        createInteraction(requestBody)
            .unwrap()
            .then(() => navigate('/memes/create/success'))
            .catch((errorResponse) => {
                console.log(errorResponse);
                if (errorResponse?.data?.length > 0) {
                    const errors = errorResponse?.data;
                    errors.forEach((error) => dispatch(addNotification({ show: true, message: error?.message, status: 'critical' })))
                } else {
                    dispatch(addNotification({ show: true, message: errorResponse?.error, status: 'critical' }))
                }
            });
    }
    
    return (
        <MemeCreateWizardStep step={3} stepTitle={t('memeInfoTitle')} prevStepTitle={t('uploadMemeTitle')} backRoute='/memes/create/upload' submit onSubmit={handleCreateMeme} busy={isCreating}>
            <Box direction="row" gap="medium">
                <Box basis="1/2">
                    {cachedVideoPreview && <VideoPlayer 
                        src={cachedVideoPreview} 
                        removeBgConfig={chromaKeyConfig}
                        autoPlay
                        controls
                        className="meme-preview"
                        maxHeight={400}
                    />}
                    {!cachedVideoPreview && (<Image src={`${process.env.PUBLIC_URL}/images/video_preview.png`} />)}
                    {/* <Form ref={formRef} validate="blur" messages={{ required: 'Required field' }} value={formData} onChange={(value) => setFormData(prevValue => ({ ...prevValue, ...value }))} onSubmit={({ value }) => {}} > */}
                        <FormField label={t('memeTitle')} htmlFor="title" name="title" required={{ indicator: true }} validate={titleValidationRules}>
                            <TextInput id="title" name="title" />
                        </FormField>
                        <FormFieldWithControls label={t('memeTagsTitle')} info={t('memeTagsInfo')}>
                            <Keyboard onEnter={handleTagAdd}>
                                <TextInput value={memeTag} onChange={handleTagInput} maxLength={30} />
                            </Keyboard>
                            <Button icon={<Add onClick={handleTagAdd}/>} />
                        </FormFieldWithControls>
                        <Box direction="row" gap="small" responsive wrap>
                            {memeTags?.map((tag, index) => (<Box margin={{ bottom: 'small' }}><Tag size="small" value={`#${tag}`} onRemove={() => setMemeTags((prevState) => prevState.filter((value, idx) => idx !== index))} /></Box>))}
                        </Box>
                    {/* </Form> */}
                </Box>
                <Box basis="1/2" background={'background-contrast'} round pad={'medium'}>
                    <Paragraph margin={'none'} responsive>
                        <Markdown components={{ h2: CustomHeading }}>{t('createMemeWizardInfoRules', { joinArrays: '\n\n' })}</Markdown>
                    </Paragraph>
                </Box>
            </Box>
        </MemeCreateWizardStep>
    )
}

const CustomHeading = ({ children, ...props }) => {
    return (<Heading margin={'none'} level={2} {...props} >{children}</Heading>)
}