import { Box, Button, Card, CardBody, CardFooter, CardHeader, Form, Heading, Text } from "grommet"
import { Add, LinkNext, LinkPrevious } from "grommet-icons"
import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import { FormContext } from "../../../pages/MemeCreatePage";

export const MemeCreateWizardStep = ({ step, stepTitle, prevStepTitle, backRoute, nextRoute, submit, onSubmit, busy, children }) => {

    const { t } = useTranslation('memes');
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);

    const onSubmitHandler = ({ value }) => {
        if (nextRoute) {
            navigate(nextRoute);
        }
        console.log(value);
        if (submit) {
            onSubmit();
        }
    }

    return (
        <Card background='#222222'>
            <Form validate="blur" messages={{ required: t('requiredField') }} value={formData} onChange={(value) => setFormData(prevValue => ({ ...prevValue, ...value }))} onSubmit={onSubmitHandler} >
                <WizardHeader prevStepTitle={prevStepTitle} title={t('createMemeWizardTitle')} onBack={() => navigate(backRoute)} />
                <CardBody border={{ size: '1px', color: 'border-weak', side: 'bottom' }}>
                    <Text><Trans i18nKey="memes:step" values={{ step: step, totalSteps: 3 }} /></Text>
                    <Heading level={2}>{stepTitle}</Heading>
                    <Box>{children}</Box>
                </CardBody>
                {(nextRoute || submit) && <CardFooter justify="end">
                    {nextRoute && <Button icon={<LinkNext />} label={t('nextButton')} type="submit" reverse />}
                    {submit && <Button icon={<Add />} label={t('createMemeButton')} type="submit" reverse primary busy={busy} />}
                </CardFooter>}
            </Form>
        </Card>
    )
}

const WizardHeader = ({ prevStepTitle, title, onBack }) => {

    return (
        <CardHeader background='background-contrast'>
            <Box direction="row" justify="center" align="center" fill>
                <Box direction="row" flex>{prevStepTitle && <Button icon={<LinkPrevious />} label={prevStepTitle} onClick={onBack} />}</Box>
                <Box><Text>{title}</Text></Box>
                <Box direction="row" flex></Box>
            </Box>
        </CardHeader>
    )
}