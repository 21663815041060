import { Box, CheckBox, FileInput, FormField, Heading, Image, Markdown, Paragraph, RangeInput, TextInput } from "grommet"
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../webgl/VideoPlayer";
import { divide, equal } from "mathjs";
import { MemeCreateWizardStep } from "./MemeCreateWizardStep";
import { FormContext } from "../../../pages/MemeCreatePage";
import { useNavigate } from "react-router-dom";

const memeTypes = {
    FULLSCREEN: 'FULLSCREEN',
    SPOT: 'SPOT',
    MIXED: 'MIXED'
}

const validateInputVideo = async (file) => {
    const url = URL.createObjectURL(file);

    const videoElement = document.createElement('video');
    videoElement.src = url;

    return await calculateAspectRatio(videoElement);
}

const calculateAspectRatio = videoElement => new Promise((resolve) => {
    videoElement.onloadedmetadata = (event) => {
        const video = event.target;
        resolve(equal(divide(video.videoWidth, video.videoHeight), divide(16, 9)));
    }
});

export const MemeCreateWizardUploadStep = () => {

    const { t } = useTranslation(['memes', 'common']);
    const navigate = useNavigate();
    const { formData, setFormData } = useContext(FormContext);

    const cachedVideoPreview = useMemo(() => {
        if (formData.video?.length > 0) {
            const videoFile = formData.video[0];

            if((formData.memeType === memeTypes.FULLSCREEN || formData.memeType === memeTypes.MIXED )) {
                validateInputVideo(videoFile).then(result => {
                    setShowAspectRatioError(!result)
                });
            }

            return URL.createObjectURL(videoFile);
        }
    }, [formData.video]);
    const [chromaKeyConfig, setChromaKeyConfig] = useState();
    const [showAspectRatioError, setShowAspectRatioError] = useState();

    useEffect(() => {
        if (!formData.memeType) {
            navigate('/memes/create')
        }
        if (formData.removeBg) {
            setChromaKeyConfig({ 
                chromaKey: formData.chromaKey,
                similarity: formData.similarity,
                smoothness: formData.smoothness,
                spill: formData.spill
            })
        } else {
            setChromaKeyConfig(null);
        }
    }, [formData])

    return (
        <MemeCreateWizardStep step={2} stepTitle={t('uploadMemeTitle')} prevStepTitle={t('selectMemeTypeTitle')} backRoute='/memes/create' nextRoute='/memes/create/info'>
            <Box direction="row" gap="medium">
                <Box basis="1/2">
                    {cachedVideoPreview && <VideoPlayer 
                        src={cachedVideoPreview} 
                        removeBgConfig={chromaKeyConfig}
                        autoPlay
                        controls
                        className="meme-preview"
                        // background={transparentBackgroundArea}
                        maxHeight={400}
                        showTransparentBg={true}
                        // style={{ maxHeight: '400px' }}
                    />}
                    {!cachedVideoPreview && (<Image src={`${process.env.PUBLIC_URL}/images/video_preview.png`} />)}
                    {/* <Form ref={formRef} validate="blur" messages={{ required: 'Required field' }} value={formData} onChange={(value) => setFormData(prevValue => ({ ...prevValue, ...value }))} onSubmit={({ value }) => {}} > */}
                        <Box direction="row" gap="medium">
                            <Box fill>
                                <FormField label={t('videoTitle')} htmlFor="video" name="video" required={{ indicator: true }} validate={() => showAspectRatioError ? t('aspectRatioError') : undefined}>
                                    <FileInput id="video" name="video" accept="video/webm, video/mp4, video/mov" /*onChange={(event, { files }) => files.length ? setVideoFile(files[0]) : setVideoFile(undefined)}*/ maxSize={30000000} messages={{
                                        browse: t('common:browse'), 
                                        dropPrompt: t('common:dropPrompt'), 
                                        dropPromptMultiple: t('common:dropPromptMultiple'), 
                                        files: t('common:files'), 
                                        remove: t('common:remove'), 
                                        removeAll: t('common:removeAll'),
                                        maxFile: t('common:maxFile')
                                    }}/>
                                </FormField>
                                <Box border={{ color: '#ffffff5c', size: '1px' }} round={'small'} pad={'small'} margin={{ top: 'xsmall' }}>
                                    {/* <Box margin={{ right: 'small', bottom: 'small' }}>
                                        <CheckBox toggle label={t('removeBackgroundTitle')} pad={'none'} checked={removeBackground} onClick={(event) => setRemoveBackground(event.target.checked)}/>
                                    </Box> */}
                                    <Box direction="column" fill>
                                        <FormField htmlFor="removeBg" name="removeBg" noBorder noHover>
                                            <CheckBox id="removeBg" name="removeBg" toggle label={t('removeBackgroundTitle')} />
                                        </FormField>
                                        <FormField label={t('backgroundColorTitle')} htmlFor="chromaKey" name="chromaKey">
                                            <TextInput id="chromaKey" name="chromaKey" type="color" style={{ padding: 0 }} />
                                        </FormField>
                                        <FormField htmlFor="similarity" label={t('similarityTitle')} noBorder>
                                            <RangeInput id="similarity" name="similarity" min={0} max={1} step={0.001} />
                                        </FormField>
                                        <FormField htmlFor="smoothness" label={t('smoothnessTitle')} noBorder>
                                            <RangeInput id="smoothness" name="smoothness" min={0} max={1} step={0.001} />
                                        </FormField>
                                        <FormField htmlFor="spill" label={t('spillTitle')} noBorder>
                                            <RangeInput id="spill" name="spill" min={0} max={1} step={0.001} />
                                        </FormField>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    {/* </Form> */}
                </Box>
                <Box basis="1/2" background={'background-contrast'} round pad={'medium'}>
                    <Paragraph margin={'none'}>
                        <Markdown components={{ h2: CustomHeading }}>{formData.memeType === memeTypes.SPOT ? t('createMemeSpotWizardRules', { joinArrays: '\n\n' }) : t('createMemeFullscreenWizardRules', { joinArrays: '\n\n' })}</Markdown>
                    </Paragraph>
                </Box>
            </Box>
        </MemeCreateWizardStep>
    )
}

const CustomHeading = ({ children, ...props }) => {
    return (<Heading margin={'none'} level={2} {...props} >{children}</Heading>)
}