import { useNavigate, useParams } from "react-router-dom";
import { BoxWindow } from "../components/BoxWindow"
import { Box, Button, Card, CardBody, Spinner, Tab, Tabs, Text, TextInput } from "grommet";
import { Add, Alert, Search } from "grommet-icons";
import { useEffect, useState } from "react";
import { MemesUserHeader } from "../components/memes/MemesUserHeader";
import { useGetInteractionSettingsQuery } from "../api/interactionsApi";
import { authenticationSelector } from "../slices/authSlice";
import { useSelector } from "react-redux";
import { TwitchAuth } from "../components/auth/TwitchAuth";
import MemesGlobalList from "../components/memes/memes-list/MemesGlobalList";
import MemesFavoritesList from "../components/memes/memes-list/MemesFavoritesList";
import { useGetConfigsQuery } from "../api/configApi";
import { useTranslation } from "react-i18next";

export const DonatePage = () => {

    const { t, ready } = useTranslation('memes', {
		useSuspense: false,
	});
    const navigate = useNavigate();
    const params = useParams();
    const isAuthenticated = useSelector(authenticationSelector);
    const [searchTerm, setSearchTerm] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const [configSet, setConfigSet] = useState({});
    const { data: configs } = useGetConfigsQuery();
    const { data: interactionSettings, isLoading: isInteractionSettingsLoading, isSuccess: isInteractionSettingsLoaded, isError: isInteractionSettingsError } = useGetInteractionSettingsQuery(params.username, { skip: !isAuthenticated })

    useEffect(() => {
        setConfigSet(configs?.reduce((accum, config) => ({ ...accum, [config.configKey]: config.value }), {}));
    }, [configs])

    if (!ready) {
        return (<Spinner/>)
    }


    if (isInteractionSettingsLoading) {
        return (
            <Box>Loading...</Box>
        )
    }

    if (isInteractionSettingsError) {
        return (
            <BoxWindow>
                <Card background="#222222" height={'100vh'} margin={{ top: 'medium' }}>
                    <CardBody pad="medium" align="center" justify="center">
                        <Alert size="xlarge" /><Text size="xlarge">{t('userNotFound')}</Text>
                    </CardBody>
                </Card>
            </BoxWindow>
        )
    }

    return (
        <Box>
            <BoxWindow>
                {isInteractionSettingsLoaded && <MemesUserHeader username={params.username} monobankJarUrl={interactionSettings.monobankJarUrl} />}
            </BoxWindow>
            <BoxWindow>
                <Card background="#222222" fill>
                    <CardBody pad="medium">
                        <Box direction="row" gap="small" align="center">
                            <Box flex>
                                <TextInput name="_search" icon={<Search/>} type="search" onChange={(event) => setSearchTerm(event.target.value)}/>
                            </Box>
                            <Box direction="row" gap="small" align="center">
                                {/* <SelectMultiple placeholder="Select filter" options={['Fullscreen memes', 'Spot memes', 'Mixed memes']} /> */}
                                {isAuthenticated && <Button label={t('memeCreateButton')} icon={<Add/>} onClick={() => navigate('/memes/create')} disabled={configSet && configSet['interactions.upload.enabled'] === "false"} />}
                                {!isAuthenticated && <TwitchAuth />}
                            </Box>
                        </Box>
                    </CardBody>
                </Card>
            </BoxWindow>
            {/* <BoxWindow bottomPadding={false}>
                <MemesTags tags={tags} />
            </BoxWindow> */}
            <BoxWindow>
                <Tabs alignControls="end" onActive={setActiveTab}>
                    <Tab title={t('tabMemesTitle')}>
                        <MemesGlobalList interactionSettings={interactionSettings} searchTerm={searchTerm} activeTab={activeTab} isDonatePage />
                    </Tab>
                    {/* <Tab title={`${params.username}'s popular`}>
                        <MemesGrid memes={popular} isDonatePage />
                    </Tab> */}
                    {isAuthenticated && <Tab title={t('tabFavoritesTitle')}>
                        <MemesFavoritesList interactionSettings={interactionSettings} searchTerm={searchTerm} activeTab={activeTab} isDonatePage />
                    </Tab>}
                </Tabs>
            </BoxWindow>
        </Box>
    )
}