import { useCallback, useEffect, useRef, useState } from "react";
import webGLUtils from "./webGLUtils";
import { fragmentShaderSource, vertexShaderSource } from "./webGLShaders";

const useWebGL = (removeBgConfig) => {

    const [canvas, setCanvas] = useState();
    const canvasRef = useCallback((node) => {
        if (!node) return;
        setCanvas(node);
    }, []);
    const videoRef = useRef(null);
    const removeBgConfigRef = useRef(removeBgConfig);
    const requestId = useRef(null);
    const webGLVariablesRef = useRef(null);

    const initWebGL = (canvas) => {
        console.log('init');
        
        const context = canvas.getContext('webgl', { premultipliedAlpha: false });
        if (!context) return null;

        const vs = webGLUtils.loadShader(context, context.VERTEX_SHADER, vertexShaderSource);
        const fs = webGLUtils.loadShader(context, context.FRAGMENT_SHADER, fragmentShaderSource);
        const prog = webGLUtils.createProgram(context, vs, fs);
        webGLUtils.createTexture(context);
        const coordLoc = context.getAttribLocation(prog, 'c');
        webGLUtils.setShaderBuffer(context, coordLoc, new Float32Array([ -1, 1, -1, -1, 1, -1, 1, 1 ]))

        return {
            context,
            texLoc: context.getUniformLocation(prog, "tex"),
            texWidthLoc: context.getUniformLocation(prog, "texWidth"),
            texHeightLoc: context.getUniformLocation(prog, "texHeight"),
            keyColorLoc: context.getUniformLocation(prog, "keyColor"),
            similarityLoc: context.getUniformLocation(prog, "similarity"),
            smoothnessLoc: context.getUniformLocation(prog, "smoothness"),
            spillLoc: context.getUniformLocation(prog, "spill"),
        };
    };

    useEffect(() => {
        if (!canvas) return;

        webGLVariablesRef.current = initWebGL(canvas);

        return () => {
            console.log('unmount');

            stop();

            const context = webGLVariablesRef.current?.context

            if (context) {
                console.log('CLEAR CONTEXT');
            
                const extension = context.getExtension('WEBGL_lose_context');
                if (extension) {
                    extension.loseContext();
                }
            }
        }
    }, [canvas]);

    useEffect(() => {
        removeBgConfigRef.current = removeBgConfig;
    }, [removeBgConfig])

    const drawFrame = () => {
        if (!videoRef.current || !webGLVariablesRef.current || !removeBgConfigRef.current) return;

        const video = videoRef.current;

        const aspectRatio = video.videoWidth / video.videoHeight;
        // const container = video.parentElement;
        const containerWidth = video.clientWidth;
        const containerHeight = video.clientHeight;

        let displayWidth, displayHeight;

        if (containerWidth / containerHeight > aspectRatio) {
        // Container is wider than video aspect ratio → Height is maxed out
            displayHeight = containerHeight;
            displayWidth = containerHeight * aspectRatio;
        } else {
        // Container is taller than video aspect ratio → Width is maxed out
            displayWidth = containerWidth;
            displayHeight = containerWidth / aspectRatio;
        }

        // console.log(`Calculated size: ${displayWidth}x${displayHeight}`);

        const { context, texLoc, texWidthLoc, texHeightLoc, keyColorLoc, similarityLoc, smoothnessLoc, spillLoc } = webGLVariablesRef.current;
        
        context.viewport(0, 0, displayWidth, displayHeight);
        context.texImage2D(context.TEXTURE_2D, 0, context.RGB, context.RGB, context.UNSIGNED_BYTE, video);

        context.uniform1i(texLoc, 0);
        context.uniform1f(texWidthLoc, displayWidth);
        context.uniform1f(texHeightLoc, displayHeight);
        
        const keyColor = removeBgConfigRef.current.chromaKey.match(/^#([0-9a-f]{6})$/i)[1];
        context.uniform3f(keyColorLoc, parseInt(keyColor.substr(0, 2), 16) / 255, parseInt(keyColor.substr(2, 2), 16) / 255, parseInt(keyColor.substr(4, 2), 16) / 255);
        context.uniform1f(similarityLoc, removeBgConfigRef.current.similarity);
        context.uniform1f(smoothnessLoc, removeBgConfigRef.current.smoothness);
        context.uniform1f(spillLoc, removeBgConfigRef.current.spill);

        context.drawArrays(context.TRIANGLE_FAN, 0, 4);
    }

    const animationLoop = () => {
        drawFrame();
        requestId.current = requestAnimationFrame(animationLoop);
    }

    const play = (videoSource) => {
        videoRef.current = videoSource;
        if (!requestId.current) requestAnimationFrame(animationLoop);
    }

    const stop = () => {
        if (requestId.current) {
            cancelAnimationFrame(requestId.current);
            requestId.current = null;
        }
    }

    return { canvasRef, play, stop };
};

export default useWebGL;
