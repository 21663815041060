import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import Video from "./Video"
import { Box, Button, RangeInput } from "grommet";
import { Pause, Play, Volume, VolumeMute } from "grommet-icons";
import WebGLVideo from "./WebGLVideo";
import { useLocalstorageState } from "rooks";

const VideoPlayer = forwardRef(({ src, removeBgConfig, controls, autoPlay, loop, muted, maxHeight, showTransparentBg, ...props }, ref) => {

    const [volumePersistent, setPlayerData] = useLocalstorageState('player_data', { muted: false, volume: 0.1 });
    const containerRef = useRef();
    const [isSmall, setSmall] = useState(false);
    const [isPlaying, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [videoNode, setVideoNode] = useState();
    const [isMuted, setMuted] = useState(volumePersistent.muted);
    const [volume, setVolume] = useState(volumePersistent.muted ? 0 : 0.1);
    const [tempVolume, setTempVolume] = useState(volumePersistent.volume);

    const videoRef = useCallback((node) => {
        if (!node) return;

        if (node.getVideo) {
            setVideoNode(node.getVideo());
        } else {
            setVideoNode(node);
        }
    }, [])

    useEffect(() => {
        if (!videoNode) return;

        if (volume > 0) {
            videoNode.volume = volume;
            setMuted(false);
        } else {
            setMuted(true);
        }
    }, [videoNode, volume])

    useEffect(() => {
        if (!videoNode) return;

        if (isMuted) {
            setTempVolume(videoNode.volume);
            setVolume(0);
        } else {
            setVolume(tempVolume);
        }
    }, [videoNode, isMuted])

    useEffect(() => {
        setPlayerData({ ...volumePersistent, ...{ muted: isMuted } });
    }, [isMuted])

    useEffect(() => {
        setPlayerData({ ...volumePersistent, ...{ volume: volume } });
    }, [volume])

    const onVideoReady = (event) => {
        const video = event.target;

        setDuration(video.duration);

        // if (autoPlay) {
        //     play();
        // }
    }
    
    const handleVideoPlay = () => {
        if (!isPlaying) {
            videoNode.play();
        }
        if (isPlaying) {
            videoNode.pause();
        }
    }

    const onVideoPlay = () => {
        setPlaying(true);
    }

    const onVideoPause = () => {
        setPlaying(false);
    }

    const handleMoveTimeline = (event) => {
        videoNode.currentTime = event.target.value;
    }

    const handleTimelinePause = () => {
        videoNode.pause();
    }

    const handleTimelinePlay = () => {
        videoNode.play();
    }

    const onVideoTimeChange = () => {
        setCurrentTime(videoNode.currentTime);
    }

    const play = () => {
        videoNode?.play();
    }

    const stop = () => {
        videoNode?.pause();
    }

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (!containerRef.current) {
                return;
            }
            const rect = containerRef.current.getBoundingClientRect();

            setSmall(rect.width < 400);
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => observer.disconnect();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            play,
            stop
        }
    }, [])

    return (
        <Box ref={containerRef}>
            {removeBgConfig && <WebGLVideo src={src} removeBgConfig={removeBgConfig} ref={videoRef} autoPlay={autoPlay} loop={loop} muted={isMuted} onTimeUpdate={onVideoTimeChange} onCanPlay={onVideoReady} onPlay={onVideoPlay} onPause={onVideoPause} maxHeight={maxHeight} showTransparentBg={showTransparentBg} {...props} />}
            {!removeBgConfig && <Video src={src} autoPlay={autoPlay} loop={loop} muted={isMuted} ref={videoRef} onTimeUpdate={onVideoTimeChange} onCanPlay={onVideoReady} onPlay={onVideoPlay} onPause={onVideoPause} {...props} style={{ maxHeight: maxHeight ? maxHeight : 'none' }} />}
            {controls && <Box direction="row" gap="xxsmall" align="center">
                <Button icon={isPlaying ? <Pause/> : <Play/>} onClick={handleVideoPlay} />
                <Box direction="row" gap="xxsmall" align="center" fill>
                    <Box basis={!isSmall ? '2/3' : 'auto'} flex>
                        <RangeInput 
                            value={currentTime} 
                            min={0} 
                            max={duration} 
                            step={0.001} 
                            onMouseDown={handleTimelinePause} 
                            onMouseUp={handleTimelinePlay} 
                            onChange={handleMoveTimeline}
                        />
                    </Box>
                    {isSmall && <Box>
                        <Button icon={isMuted ? <VolumeMute/> : <Volume/>} onClick={() => setMuted(prev => !prev)} />
                    </Box>}
                    {!isSmall && <Box direction="row" basis="1/4" align="center" gap="xsmall">
                        <Button icon={isMuted ? <VolumeMute/> : <Volume/>} onClick={() => setMuted(prev => !prev)} />
                        <RangeInput
                            min={0}
                            max={1}
                            value={volume}
                            step={0.001}
                            onChange={(event) => setVolume(event.target.value)}
                        />
                    </Box>}
                </Box>
            </Box>}
        </Box>
    )
})

export default VideoPlayer;