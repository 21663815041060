import { Box, Button, Card, CardBody, CardFooter, CardHeader, Heading, Layer, Tag, Text } from "grommet"
import { Close, Favorite, Money, Previous, User } from "grommet-icons"
import { SpotMemeWindow } from "../spot-meme-preview/SpotMemeWindow"
import { useCallback, useEffect, useState } from "react"
import { useSendInteractionMutation } from "../../../api/interactionsApi"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useAddToFavoritesMutation, useRemoveFromFavoritesMutation } from "../../../api/userApi"
import { formattedPrice } from "../../../utils/priceUtils"
import { authenticationSelector } from "../../../slices/authSlice"
import { addNotification } from "../../../slices/notificationsSlice"
import { Trans, useTranslation } from "react-i18next"
import VideoPlayer from "../../webgl/VideoPlayer"

export const MemeDialog = ({ meme, priceConfig, onClose, isDonatePage }) => {

    const { t } = useTranslation('memes');
    const isAuthenticated = useSelector(authenticationSelector);
    const params = useParams();
    const dispatch = useDispatch();
    const [showSpotWindow, setShowSpotWindow] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [chromaKeyConfig, setChromaKeyConfig] = useState();
    const [sendInteraction, { isLoading: isSending }] = useSendInteractionMutation();
    const [addToFavorites, { isLoading: isAddingToFavorites }] = useAddToFavoritesMutation();
    const [removeFromFavorites, { isLoading: isRemovingFromFavorites }] = useRemoveFromFavoritesMutation();
    const [isFavorite, setIsFavorite] = useState();

    const handleClose = useCallback(() => {
        console.log('close');
        
        setShowVideo(false);
        setShowSpotWindow(false);
        onClose();
    }, [onClose])

    const handleSendFullscreenInteraction = useCallback(() => {
        sendInteraction({ interactionId: meme.id, recipient: params.username })
            .unwrap()
            .then(() => {
                handleClose();
                dispatch(addNotification({ show: true, message: 'Sent interaction successfully.', status: 'normal' }));
            })
            .catch((errorResponse) => {
                console.log(errorResponse);
                if (errorResponse?.data?.length > 0) {
                    const errors = errorResponse?.data;
                    errors.forEach((error) => dispatch(addNotification({ show: true, message: error?.message, status: 'critical' })))
                } else {
                    dispatch(addNotification({ show: true, message: errorResponse?.data?.message, status: 'critical' }))
                }
            });
    }, [meme, params])

    const handleAddToFavorites = () => {
        addToFavorites({ interactionId: meme.id })
            .then(_ => setIsFavorite(true));
    }

    const handleRemoveFromFavorites = () => {
        removeFromFavorites({ interactionId: meme.id })
            .then(_ => setIsFavorite(false));
    }

    useEffect(() => {
        if (meme?.chromaKey) {
            setChromaKeyConfig({ 
                chromaKey: meme.chromaKey,
                similarity: meme.similarity,
                smoothness: meme.smoothness,
                spill: meme.spill 
            });
        } else {
            setChromaKeyConfig(null);
        }
        setIsFavorite(meme?.isFavorite);
        // setShowVideo(true);
    }, [meme])

    return (
        <Layer onClickOutside={handleClose} onAnimationEnd={() => setShowVideo(true)} animation="fadeIn">
            <Card background="#222222">
                <CardHeader pad={{ left: 'medium', right: 'medium', top: 'medium', bottom: 'none' }}>
                    <Box>
                        {!showSpotWindow && (<Heading level={2} weight={'bold'} margin={'none'}>{meme.title}</Heading>)}
                        {showSpotWindow && (<Button icon={<Previous/>} onClick={() => setShowSpotWindow(false)} />)}
                    </Box>
                    <Box direction="row" gap="small" align="center">
                        {meme.isFullscreen && !showSpotWindow && (<Box pad={'xxsmall'} round="xsmall" background={'#026b10'} align="center">Fullscreen</Box>)}
                        {meme.isSpot && !showSpotWindow && (<Box pad={'xxsmall'} round="xsmall" background={'#bf7104'} align="center">Spot</Box>)}
                        {!meme.isFullscreen && !meme.isSpot && !showSpotWindow && (<Box pad={'xxsmall'} round="xsmall" background={'#4b17b3'} align="center">Mixed</Box>)}
                        <Button icon={<Close/>} onClick={handleClose}/>
                    </Box>
                </CardHeader>
                <CardBody pad={{ left: 'medium', right: 'medium', top: 'medium', bottom: 'small' }}>
                    {!showSpotWindow && (<><Box direction="row" gap="medium" margin={{ bottom: 'medium' }}>
                        <Box>
                            {showVideo && <VideoPlayer 
                                src={meme.interactionVideo} 
                                removeBgConfig={chromaKeyConfig}
                                autoPlay
                                controls
                                className="meme-preview"
                                maxHeight={400}
                            />}
                        </Box>
                        <Box direction="column" gap="medium" fill>
                            {isDonatePage && priceConfig && (meme.isSpot || !meme.isFullscreen) && <Button label={<Trans i18nKey="memes:sendMemeButton" values={{ cost: formattedPrice(priceConfig.price) }}/>} disabled={!priceConfig.isEnabled} icon={<Money/>} primary reverse onClick={() => meme.isSpot || !meme.isFullscreen ? setShowSpotWindow(true) : null}/>}
                            {isDonatePage && priceConfig && (meme.isFullscreen || !meme.isSpot) && (<Button label={<Trans i18nKey="memes:sendFullscreenMemeButton" values={{ cost: formattedPrice(priceConfig.price * priceConfig.priceMultiplier) }}/>} disabled={!priceConfig.isEnabled} icon={<Money/>} primary reverse busy={isSending} onClick={handleSendFullscreenInteraction}/>)}
                            {isAuthenticated && !isFavorite && <Button label={t('favoriteMemeButton')} icon={<Favorite/>} secondary reverse onClick={handleAddToFavorites} busy={isAddingToFavorites} />}
                            {isAuthenticated && isFavorite && <Button label={t('unfavoriteMemeButton')} icon={<Favorite className="fav-fill" />} secondary reverse onClick={handleRemoveFromFavorites} busy={isRemovingFromFavorites} />}
                        </Box>
                    </Box>
                    <Box direction="row-responsive" gap="small" responsive wrap>
                        {meme.tags?.map(tag => <Box margin={{ bottom: 'small' }}><Tag size="small" value={`#${tag}`} /></Box>)}
                    </Box></>)}
                    {showSpotWindow && priceConfig && (<SpotMemeWindow meme={meme} priceConfig={priceConfig} onClose={handleClose} />)}
                </CardBody>
                <CardFooter pad={{ left: 'medium', right: 'medium', bottom: 'medium' }} justify="start">
                    {!showSpotWindow && (
                        <Box direction="row" gap="small">
                            <Box direction="row" align="center" gap="xsmall">
                                <User/>
                                <Text weight={'bold'}>{meme.createdBy}</Text>
                            </Box>
                            {/* <Box direction="row" align="center" gap="xsmall">
                                <Send/>
                                <Text>847</Text>
                            </Box> */}
                        </Box>
                    )}
                </CardFooter>
            </Card>
        </Layer>
    )
}