import { FormField } from "grommet"
import "./Form.css"

export const FormFieldWithControls = ({ children, ...props }) => {

    return (
        <FormField {...props} className="form-with-controls">
            {children}
        </FormField>
    )
}